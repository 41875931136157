body, html {
    margin: 0;
    padding: 0;
}
/* media here*/
.hero-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.hero-container > h1{
    color: #fff;
    font-size: 200px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.hero-container > p {
    margin-top: 1px;
    color:  #ffff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


