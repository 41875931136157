
.App { 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
}
body {
  background-color: black;
  margin: 10px;
  padding: 5px;
  font-family: "Orbitron", sans-serif;
}


.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; 
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.overlay{
  position: absolute;
  top: 15px; 
  left: 15px; 
  right: 15px; 
  bottom: 15px;
  padding: 15px; 
  border: 1px solid rgb(255, 0, 0); 

  font-size: 24px; 
  pointer-events: none;
  box-sizing: border-box; 
}